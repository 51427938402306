<template>
      <b-row>
        <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTable"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="clients"
            :fields="field"
            @row-dblclicked="editClient"
            @row-clicked="clicked"
            @row-contextmenu="handleContextMenuEvent"
            head-row-variant="primary"
          >
          <template #cell(checkbox)="row">
              <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
              >
              </b-form-checkbox>
          </template>
            <template #cell(check)="data">
              <span @dblclick="getAssigment(data)" class="reference-field">
                (***)
              </span>
            </template>
            <template #cell(first_name)="data">
              <span>
                {{
                  (data.item.last_name ? data.item.last_name : '') +
                  " " +
                  (data.item.first_name ? data.item.first_name : '') +
                  " " +
                  (data.item.patronymic ? data.item.patronymic : '')
                }}
              </span>
            </template>
            <template #cell(gender)="data">
              <span>
                {{ data.item.gender === 0 ? "Женский" : "Мужской" }}
              </span>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                v-b-modal.messageCreateModal
                @click="messageCreateModal"
              >
                <feather-icon icon="MailIcon" />
                <span class="ml-75">Отправить СМС</span>
              </b-link>
              <b-link
                class="d-flex align-items-center"
                v-b-modal="$route.name + 'CreateModal'"
              >
                <feather-icon icon="PlusIcon" />
                <span class="ml-75">Добавить</span>
              </b-link>
              <b-link
                class="d-flex align-items-center"
                @click="editClient(id)"
              >
                <feather-icon icon="EditIcon" />
                <span class="ml-75">Изменить</span>
              </b-link>
            </li>
          </vue-context>
            </b-card>
        </b-col>
      </b-row>
</template>

<script>
import VueContext from "vue-context";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    components: {
        VueContext,
    },
    props: ['clients'],
        data() {
            return {
                field: [
                { key: 'checkbox', label: 'Флаг', sortable: true, thStyle: {width: '5px'}},
                { key: 'id', label: "ID ", sortable: true, thStyle: { width: "5px" } },
                { key: "division_name", label: "Подразделение", sortable: true, thStyle: { width: "30px" } },
                { key: "first_name", label: "ФИО", sortable: true, thStyle: { width: "30px" } },
                { key: "gender", label: "Пол", sortable: true, thStyle: { width: "30px" } },
                { key: "phone", label: "Телефон", sortable: true, thStyle: { width: "30px" } },
                { key: "balance", label: "Баланс", sortable: true, thStyle: { width: "30px" } },
                { key: "birth_date", label: "Дата рождения", sortable: true, thStyle: { width: "30px" } },
                { key: "count_orders", label: "Количество заказов", sortable: true, thStyle: { width: "50px" } },
                { key: "client_status_info", label: "Статус входа в приложении", sortable: true, thStyle: { width: "50px" } },
                { key: "last_auth_date_mob_app", label: "Последняя дата входа", sortable: true, thStyle: { width: "70px" } },
                { key: "email", label: "Email", sortable: true, thStyle: { width: "30px" } },
                { key: "is_active", label: "Лицевые счета", sortable: true, thStyle: { width: "30px" } },
                { key: "dop_info", label: "Дополнительная информация", sortable: true, thStyle: { width: "30px" } }
                ],   
                id: null   
            }
        },
        mounted() {
            resizeable()
        },
        methods: {
            getAssigment(data) {
                this.$store.commit("draggableTab/ADD_TAB", [
                    "Лицевые счета " + data.item.id,
                    "/clients/balances",
                    data.item.id,
                ]);
                this.$router.push({
                    name: "clients/balances",
                    query: { id: data.item.id },
                });
            },
            editClient(item) {
                this.$emit('editClient', item)
            },
            handleContextMenuEvent(item, index, event) {
                this.id = item
                this.clientPhone = item.phone;
                event.preventDefault();
                this.$refs.menu.open(event);
            },
            messageCreateModal() {
                this.$emit('messageCreateModal', this.id)
            },
            clicked(item, index, event) {   
              this.$store.state.filterPlus.tableIndex = index
              const clickedElement = event.target;
              const clickedCell = clickedElement.closest('td');
          
              if (!clickedCell) {
                  return;
              }
          
              const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);
          
              if (columnIndex < 0 || columnIndex >= this.field.length) {
                  return;
              }
          
              const clickedField = this.field[columnIndex];
              this.$store.state.filterPlus.keyFilter = clickedField.key;
              this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
              cellSelect()
            },
        }
    }
</script>

<style  lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card {
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style>